(function() {
  var answer_yes, close_overlay, flash_error, flash_success, show_overlay;

  flash_success = function(title, message) {
    $("#flash-title").html(title);
    $("#flash-content").html(message);
    $('#flash').css('background-color', 'rgba(117, 224, 109, 0.6)');
    $('#flash').css('color', 'rgba(40, 40, 40, 0.9)');
    return $("#flash").fadeIn(500).delay(3500).fadeOut(500);
  };

  flash_error = function(title, message) {
    $("#flash-title").html(title);
    $("#flash-content").html(message);
    $('#flash').css('background-color', 'rgba(252, 93, 90, 0.6)');
    $('#flash').css('color', 'rgba(40, 40, 40, 0.9)');
    return $("#flash").fadeIn(200).delay(3500).fadeOut(200);
  };

  show_overlay = function(h2) {
    if (h2 == null) {
      h2 = false;
    }
    $(".info-overlay > div").hide();
    if (h2) {
      $('.info-overlay .info h2').html(h2);
    }
    $(".info-overlay .info").show();
    return $(".info-overlay").fadeIn(500);
  };

  close_overlay = function() {
    $(".info-overlay").fadeOut(500);
    // return $.ajax({
    //   url: "/toolboxes/" + $('#event-wrapper').attr('event-id')
    // });
  };


  if ($('#event-wrapper')) {
    var event_id = $('#event-wrapper').attr('event-id');
  }

  // answer_yes = function(data) {
  //   var event_id;
  //   event_id = $('#event-wrapper').attr('event-id');
  //   return $.ajax({
  //     url: "/events/" + event_id,
  //     type: "put",
  //     data: {
  //       event: data
  //     },
  //     success: function() {
  //       return $.ajax({
  //         url: "/toolboxes/" + event_id,
  //         success: function() {
  //           return console.log("UPDATED");
  //         }
  //       });
  //     }
  //   });
  // };

  

  $(document).on("focus", "[data-behaviour~='datepicker']", function(e) {
    return $(this).datetimepicker({
      format: "Y-MM-D HH:mm"
    });
  });

  $(document).on('click', '#juniors', function() {
    event_id = $('#event-wrapper').attr('event-id');
    return $.ajax({
      url: "/events/" + event_id,
      type: "put",
      data: {
        event: {
          'juniors': !$('#juniors').hasClass('active')
        },
        render_plain: true
      },
      success: function() {
        return location.reload();
      }
    });
  });

  $(document).on('click', '#assign-random-times', function() {
    event_id = $('#event-wrapper').attr('event-id');
    return $.ajax({
      url: "/events/" + event_id + "/assign_random_times",
      type: "get",
      success: function() {
        return location.reload();
      }
    });
  });

  $(document).on('click', '#delete-all-times', function() {
    event_id = $('#event-wrapper').attr('event-id');
    return $.ajax({
      url: "/events/" + event_id + "/delete_all_times",
      type: "get",
      success: function() {
        return location.reload();
      }
    });
  });

  if (($('#event-preview').length)) {
    setInterval((function() {
      $.ajax({
        url: "/events/" + $('#event-preview').attr('event-id') + "/get_current_offset",
        success: function(offset) {
          console.log(offset);
          console.log("HEZ");
          if (offset !== $('#event-preview').attr('offset')) {
            console.log("RELOAD");
            return location.reload();
          }
        }
      });
    }), 3000);
  }
  $(document).on('click', '.table-options .fa-search', function() {
    // $('.table-options i').toggle();
    $('#search-table')
    $('#search-slider').toggle("slide");
    if ($(this).hasClass('fa-close')) {
      $('#search-table').attr('value', '');
      $('#search-table').val('');
      return $.each($('#eventriders-table tbody tr'), function() {
        return $(this).show();
      });
    } else {
      return $('#search-table').focus();
    }
  });
  $(document).on('click', '#mixed', function() {
    return answer_yes({
      mixed: !$('#mixed').hasClass('active')
    });
  });
  $(document).on('keyup', '#event-type-input', function(e) {
    if (e.keyCode === 13) {
      return answer_yes({
        event_type: $('#event-type-input').val()
      });
    }
  });
  $(document).on('click', '.multi-bar .show-bar', function(e) {
    $(".multi-bar").removeClass('hide-it');
    return $('#edit-wrapper').removeClass('show-full');
  });
  $(document).on('click', '.multi-bar .hide-bar', function(e) {
    $(".multi-bar").addClass('hide-it');
    return $('#edit-wrapper').addClass('show-full');
  });
  $(document).on('click', '.multi-bar .menus li', function(e) {
    var box_string;
    if (!($(this).hasClass('hide-bar') || $(this).hasClass('show-bar'))) {
      box_string = $(this).attr('box-string');
      $('.toolbox .box').removeClass('active');
      $('.toolbox .box[box-string=' + box_string + ']').addClass('active');
      $('.multi-bar .menus li').removeClass('active');
      return $(this).addClass('active');
    }
  });
  $(document).on('keyup', '#atsx-event-id', function(e) {
    var atsx_id, event_id;
    if (e.keyCode === 13) {
      atsx_id = $("#atsx-event-id").first().val();
      event_id = $('#event-wrapper').attr('event-id');
      return $.ajax({
        url: "/events/" + event_id,
        type: "put",
        data: {
          event: {
            'atsx_event_id': atsx_id
          },
          render_plain: true
        },
        success: function() {
          return console.log("DONE");
        }
      });
    }
  });
  $(document).on('keyup', '#ticketteer-date-id', function(e) {
    var ticketteer_id;
    if (e.keyCode === 13) {
      ticketteer_id = $("#ticketteer-date-id").first().val();
      event_id = $('#event-wrapper').attr('event-id');
      return $.ajax({
        url: "/events/" + event_id,
        type: "put",
        data: {
          event: {
            'ticketteer_date_id': ticketteer_id
          },
          render_plain: true
        },
        success: function() {
          return console.log("DONE");
        }
      });
    }
  });
  $(document).on('keyup', '#event-location-input', function(e) {
    var location;
    if (e.keyCode === 13) {
      location = $("#event-location-input").first().val();
      event_id = $('#event-wrapper').attr('event-id');
      return $.ajax({
        url: "/events/" + event_id,
        type: "put",
        data: {
          event: {
            'location': location
          },
          render_plain: true
        },
        success: function() {
          return console.log("DONE");
        }
      });
    }
  });
  $(document).on('keyup', '#set_current_heat', function(e) {
    var heat_id;
    if (e.keyCode === 13) {
      heat_id = $("#set_current_heat").first().val();
      event_id = $('#event-wrapper').attr('event-id');
      return $.ajax({
        url: "/events/" + event_id,
        type: "put",
        data: {
          event: {
            'current_heat': heat_id
          },
          render_plain: true
        },
        success: function() {
          return flash_success("Current Heat set", "set to " + heat_id);
        }
      });
    }
  });
  $(document).on('click', 'offset-next', function(e) {
    event_id = $('#event-wrapper').attr('event-id');
    return $.ajax({
      url: "/events/" + event_id + "/offset_next",
      success: function() {
        return flash_success("Current offset changed", "Plus one");
      }
    });
  });
  $(document).on('keyup', '#set_current_offset', function(e) {
    var offset;
    if (e.keyCode === 13) {
      offset = $("#set_current_offset").first().val();
      event_id = $('#event-wrapper').attr('event-id');
      return $.ajax({
        url: "/events/" + event_id + "/set_current_offset",
        type: "put",
        data: {
          current_offset: offset
        },
        success: function() {
          return flash_success("Current offset set", "set to " + heat_id);
        }
      });
    }
  });
  $(document).on('click', '.event-download a', function() {
    var format, href, link, type;
    format = $(this).parent().find('.active').attr('format');
    link = $(this).attr('link');
    type = $(this).attr('type');
    href = link + '.' + format + "?list_type=" + type;
    if (format === 'pdf') {
      return window.open(href, '_blank');
    } else {
      return window.location.href = href;
    }
  });
  $(document).on('click', '.payment-rider', function() {
    var $name, $tr, id;
    $('.info-overlay > div').hide();
    $('.info-overlay .payment').show();
    $('.info-overlay').fadeIn(300);
    $tr = $(this).parent().parent();
    id = $tr.attr('rider-id');
    $name = $('.info-overlay .payment .rider-name').attr('rider-id', id);
    return $name.html($tr.find('.td-lastname').html() + " " + $tr.find('.td-firstname').html());
  });
  $(document).on('click', '.info-overlay .cancel', function() {
    $('.info-overlay').show();
    $('.info-overlay').removeClass('show');
    $('.info-overlay > div').removeClass('show');
    return $('.info-overlay').fadeOut(300);
  });
  $(document).on('click', '#eventriders-table th', function() {
    return setTimeout((function() {
      var asc, dsc, order, th, type;
      asc = $('th[aria-sort=ascending]').index();
      dsc = $('th[aria-sort=descending]').index();
      if (asc === -1) {
        th = dsc;
      } else {
        th = asc;
      }
      if (asc > 0) {
        order = '0';
      } else {
        order = '1';
      }
      type = $('#type-frame').attr('type');
      Cookies.set('sort_' + type, order + '_' + th);
    }), 500);
  });
  if ($("#event-preview").length > 0) {
    $('#header').hide();
  }
  $(document).on('click', '#delete-lineup', function() {
    return $.ajax({
      url: "riders",
      type: "delete",
      success: function(msg) {
        return flash_success('Riders removed', 'Table reloaded');
      }
    });
  });


  $(document).on('click', '#import-atsx-lineup', function() {
    var id;
    id = $('#atsx-event-id').val();
    if (id) {
      $('.import-running').fadeIn(250);
      return $.ajax({
        url: "import_lineup_from_atsx?atsx_event_id=" + id,
        success: function(msg) {
          flash_success('Import was sucessfull', 'Table reloaded');
          return $('.import-running').fadeOut(250);
        },
        error: function(res) {
          flash_error('Error occured', res.responseText);
          return $('.import-running').hide();
        }
      });
    }
  });
  $(document).on('click', '.table-filters[type="time_trials"] i', function() {
    $('.table-filters i').removeClass('active');
    $(this).addClass('active');
    if ($(this).is('#aligned-only')) {
      return $('.td-num[val="-"]').each(function() {
        return $(this).parent().hide();
      });
    } else {
      return $('.td-num[val="-"]').each(function() {
        return $(this).parent().show();
      });
    }
  });
  $(document).on('click', '#upload-results', function() {
    $('.import-running').fadeIn(250);
    return $.ajax({
      url: "upload_results_to_atsx",
      success: function(msg) {
        flash_success('Upload was sucessfull', 'Thank You!');
        return $('.import-running').fadeOut(250);
      },
      error: function(res) {
        flash_error('Error occured', res.responseText);
        return $('.import-running').hide();
      }
    });
  });
  // $(document).on('change', function() {
  //   return $('select.colorpicker-picker-longlist').simplecolorpicker({
  //     picker: true,
  //     theme: 'glyphicons'
  //   });
  // });
  $(document).on('click', '.color-wrapper .text', function(evt) {
    return $(this).parent().find('.simplecolorpicker').click();
  });
  $(".edit").on('click', function() {
    $('.title-wrapper input').trigger('click');
    return setTimeout(function() {
      return location.reload();
    }, 1000);
  });
  $(document).on('click', "#event_starts", function() {
    return setTimeout((function() {
      return $('.datepicker-dropdown').css('position', 'absolute').css('left', '930px').css('background', 'white').css('top', '221px');
    }), 20);
  });
  $(document).on('click', '.teaser-wrapper img', function() {
    return $('.teaser-wrapper input').click();
  });
  $('.teaser-wrapper input').on('change', function() {
    return $('#submit input').click();
  });
  $('#event-wrapper .more').on('click', function() {
    $('.sub-wrapper').toggle();
    return $('.teaser-wrapper').toggle();
  });

  $("#edit-wrapper #event-type #female").on('click', function() {
    $('#event_women').prop('checked', true);
    $('#event_mixed').prop('checked', false);
    $("#event-type #female").addClass('active');
    $("#event-type #male").removeClass('active');
    return $("#event-type #mixed").removeClass('active');
  });
  $("#edit-wrapper #event-type #mixed").on('click', function() {
    $('#event_women').prop('checked', false);
    $('#event_mixed').prop('checked', true);
    $("#event-type #female").removeClass('active');
    $("#event-type #male").removeClass('active');
    return $("#event-type #mixed").addClass('active');
  });
   $("#edit-wrapper #event-type #male").on('click', function() {
    $('#event_women').prop('checked', false);
    $('#event_mixed').prop('checked', false);
    $("#event-type #female").removeClass('active');
    $("#event-type #male").addClass('active');
    return $("#event-type #mixed").removeClass('active');
  });

  $(document).on('ready page:load', function() {
    // $("#rider-select").select2({
    //   placeholder: "Select a rider",
    //   allowClear: true
    // });

    if($('#rider-select')){
      $('#rider-select').select2()
    }
    $("#customers_select").empty().trigger('change');
    return $("#update-rider-nation").select2({
      placeholder: "Select a nation",
      allowClear: true
    });
  });

  $(document).on('click', ".box-chooser li", function() {
    $(this).parent().children().removeClass('active');
    return $(this).addClass('active');
  });

  $(".publish i").on('click', function() {
    return $(this).parent().find("i").toggleClass("active");
  });

}).call(this);
console.log('EVENT ENDE');

(function() {

  console.log('eventriders');

  var reload_view;

  reload_view = function(event_id) {
    var category, type;
    $('.table-overlay').fadeIn(200);
    type = $('#table-chooser li.active').attr('value');
    if ($('#tab-chooser')) {
      type = $('#tab-chooser li.active').attr('value');
    }
    category = $('.category-filter li.active').attr('value');
    return $.ajax({
      url: "/events/" + event_id + "/edit",
      format: "js",
      data: {
        event_id: event_id,
        category: category,
        type: type
      },
      success: function(data) {
        var order;
        type = $('#type-frame').attr('type');
        if (Cookies.get('sort_' + type)) {
          order = Cookies.get('sort_' + type).split('_');
          if (order[1] !== '-1') {
            $("#eventriders-table").trigger("sorton", [[[order[1], order[0]]]]);
          }
        }
        return $('.table-overlay').fadeOut(200);
      },
      error: function(data) {
        return $('.table-overlay').fadeOut(200);
      }
    });
  };

  var flash_error, flash_success, reload_view, reset_table_input, rider_has_paid, send_number_update, send_rider_update, send_time_update, set_license, update_error, update_success, update_time, update_toolboxes;

  flash_success = function(title, message) {
    $("#flash-title").html(title);
    $("#flash-content").html(message);
    $('#flash').css('background-color', 'rgba(117, 224, 109, 0.6)');
    $('#flash').css('color', 'rgba(40, 40, 40, 0.9)');
    return $("#flash").fadeIn(500).delay(3500).fadeOut(500);
  };

  flash_error = function(title, message) {
    $("#flash-title").html(title);
    $("#flash-content").html(message);
    $('#flash').css('background-color', 'rgba(252, 93, 90, 0.6)');
    $('#flash').css('color', 'rgba(40, 40, 40, 0.9)');
    return $("#flash").fadeIn(200).delay(3500).fadeOut(200);
  };

  reload_view = function(event_id) {
    var category, type;
    $('.table-overlay').fadeIn(200);
    type = $('#table-chooser li.active').attr('value');
    if ($('#tab-chooser')) {
      type = $('#tab-chooser li.active').attr('value');
    }
    category = $('.category-filter li.active').attr('value');
    return $.ajax({
      url: "/events/" + event_id + "/edit",
      format: "js",
      data: {
        event_id: event_id,
        category: category,
        type: type
      },
      success: function(data) {
        var order;
        type = $('#type-frame').attr('type');
        if (Cookies.get('sort_' + type)) {
          order = Cookies.get('sort_' + type).split('_');
          if (order[1] !== '-1') {
            $("#eventriders-table").trigger("sorton", [[[order[1], order[0]]]]);
          }
        }
        return $('.table-overlay').fadeOut(200);
      },
      error: function(data) {
        return $('.table-overlay').fadeOut(200);
      }
    });
  };


  // update_toolboxes = function() {
  //   return $.ajax({
  //     url: "/toolboxes/" + $('#event-wrapper').attr('event-id')
  //   });
  // };

  reset_table_input = function() {
    var $prev;
    $prev = $('#eventriders-table td > input:visible');
    if ($prev) {
      $prev.val($prev.parent().attr('val'));
      $prev.hide();
    }
    return $('.time-inputs:visible').hide();
  };

  update_time = function(index, parent) {
    var $time_inputs;
    $time_inputs = parent.find('.time-inputs');
    $time_inputs.show();
    return $time_inputs.find('input').show();
  };

  send_number_update = function($input) {
    var $td, $tr, id, number;
    $td = $input.parent();
    $tr = $td.parent();
    number = $input.val();
    id = $tr.attr('eventrider-id');
    return $.ajax({
      url: "/eventriders/" + id,
      type: "put",
      data: {
        eventrider: {
          number: number
        }
      },
      success: function(res) {
        var order, type;
        if (number === '') {
          number = '-';
        } else {
          number = String('000' + number).slice(-3);
        }
        $td.html($td.html().replace($td.attr('val'), number));
        $td.attr('val', number);
        flash_success('Eventrider saved', 'New number is ' + number);
        reset_table_input();
        // update_toolboxes();
        $("#eventriders-table").trigger("update");
        type = $('#type-frame').attr('type');
        if (Cookies.get('sort_' + type)) {
          order = Cookies.get('sort_' + type).split('_');
          if (order[1] !== '-1') {
            return $("#eventriders-table").trigger("sorton", [[[order[1], order[0]]]]);
          }
        }
      },
      error: function(res) {
        console.log(res);
        return flash_error('Error occured', res.responseText);
      }
    });
  };

  send_time_update = function($inputs) {
    var $td, hun_ms, id, min_ms, round, sec_ms, value;
    min_ms = $inputs.find('.min:visible').val() * 60 * 1000;
    sec_ms = $inputs.find('.sec:visible').val() * 1000;
    hun_ms = $('.hun:visible').val() * 10;
    id = $inputs.parent().parent().attr('eventrider-id');
    round = $inputs.attr('round');
    $td = $inputs.parent();
    value = min_ms + sec_ms + hun_ms;
    if ($inputs.find('.sec:visible').val() === '-1') {
      value = -1;
    }
    return $.ajax({
      url: "/eventriders/" + id + "?type=" + $('.tab-chooser li.active').attr('value'),
      type: "put",
      data: {
        time: value,
        round: round
      },
      success: function(msg) {
        var order, type;
        type = $('#type-frame').attr('type');
        if (Cookies.get('sort_' + type)) {
          order = Cookies.get('sort_' + type).split('_');
          if (order[1] !== '-1') {
            return $("#eventriders-table").trigger("sorton", [[[order[1], order[0]]]]);
          }
        }
      },
      error: function(res) {
        return flash_error('Error occured', res.responseText);
      }
    });
  };

  update_error = function(res) {
    return flash_error('Something went wrong', res.responseText);
  };

  update_success = function(res) {
    return flash_success('Update Succesfull', 'Rider data has been updated');
  };

  send_rider_update = function(id, data, success, error) {
    if (success == null) {
      success = update_success;
    }
    if (error == null) {
      error = update_error;
    }
    return $.ajax({
      url: "/riders/" + id,
      type: "put",
      data: {
        rider: data
      },
      success: success,
      error: error
    });
  };

  rider_has_paid = function($parent) {
    return $parent.find('.payment-rider i.paid').length === 1;
  };

  set_license = function($i, data) {
    var id, success;
    id = $i.parent().parent().find('h2').attr('rider-id');
    success = function() {
      // update_toolboxes();
      flash_success('Payment Succesfull', 'Season license activated');
      $('tr[rider-id=' + id + ']').find('.payment-rider i').addClass('paid');
      return $('.info-overlay').fadeOut(500);
    };
    return send_rider_update(id, data, success);
  };


    $(document).on('click', '#tab-chooser li', function() {
      $('#tab-chooser li').removeClass('active');
      $(this).addClass('active');
      event_id = $('#event-wrapper').attr('event-id');
      console.log("WE ARE HER WITH "+event_id)
      return reload_view(event_id);
    });

    $(document).on('click', '#sync-ticketteer', function() {
      event_id = $('#event-wrapper').attr('event-id');
      $('.import-running').fadeIn(250);
      $.ajax({
        url: "/events/" + event_id + "/sync_ticketteer",
        success: function(msg) {
          flash_success('Ticketteer Sync was sucessfull', 'Table reloaded');
          return $('.import-running').fadeOut(250);
        },
        error: function(res) {
          flash_error('Error occured', res.responseText);
          return $('.import-running').hide();
        }
      });
    });

    $(document).on('click', '#check-in-all', function() {
      category_id = $('.box-chooser li.active').attr('category-id');
      event_id = $('#event-wrapper').attr('event-id');
      $('.import-running').fadeIn(250);
      $.ajax({
        url: "/events/" + event_id + "/check_in_all?category_id=" + category_id,
        success: function(msg) {
          flash_success('Check In completed', 'Table reloaded');
          return $('.import-running').fadeOut(250);
        },
        error: function(res) {
          flash_error('Error occured', res.responseText);
          return $('.import-running').hide();
        }
      });
    });



    $(document).on('dblclick', '.td-1st_run', function() {
      return update_time(1, $(this));
    });
    $(document).on('dblclick', '.td-2nd_run', function() {
      return update_time(2, $(this));
    });
    $(document).on('dblclick', '.td-3rd_run', function() {
      return update_time(3, $(this));
    });
    $(document).on('click', '.license .season', function() {
      return set_license($(this), {
        license_type: 'season',
        license_amount: 40,
        license_currency: 'EUR'
      });
    });
    $(document).on('click', '.license .event', function() {
      return set_license($(this), {
        license_type: 'event',
        license_amount: 15,
        license_currency: 'EUR'
      });
    });

    $(document).on('keyup', '.td-num input', function(e) {
      if (e.keyCode === 13) {
        flash_success('Time update', 'Please wait a second!');
        if ($(this).parent().hasClass('time-inputs')) {
          return send_time_update($(this).parent());
        } else {
          return send_number_update($(this));
        }
      }
    });
    $(document).on('keyup', '.td-1st_run input', function(e) {
      if (e.keyCode === 13) {
        return send_time_update($(this).parent());
      }
    });
    $(document).on('keyup', '.td-2nd_run input', function(e) {
      if (e.keyCode === 13) {
        return send_time_update($(this).parent());
      }
    });
    $(document).on("dblclick", "tbody .td-num", function() {
      reset_table_input();
      return $(this).find('input').show();
    });
    $(document).on("click", function(event) {
      if (!$(event.target).is('input')) {
        return reset_table_input();
      }
    });
    $(document).on('click', "#add-rider", function() {
      var number, rider_id;
      number = $('#rider-number').val();
      rider_id = $('#rider-select').val();
      return $.ajax({
        url: "/eventriders/",
        type: "post",
        data: {
          event_id: event_id,
          eventrider: {
            number: number,
            rider_id: rider_id
          }
        }
      });
    });
    $(document).on('click', '#eventriders-table .sort', function() {
      return console.log('here', $(this).parent().index());
    });
    $(document).on('click', ".category-filter li", function() {
      var category;
      category = $('.category-filter li.active').attr('value');
      Cookies.set('current_category', category);
      var event_id = $('#event-wrapper').attr('event-id');
      return reload_view(event_id);
    });
    $(document).on('click', '#table-chooser', function() {
      return reload_view(event_id);
    });


    $(document).on('click', ".delete-rider", function() {
      var eventrider_id;
      event_id = $('#event-wrapper').attr('event-id');
      eventrider_id = $(this).attr('eventrider-id');
      return $.ajax({
        url: "/eventriders/" + eventrider_id,
        type: "delete",
        data: {
          event_id: event_id,
          id: eventrider_id
        }
      });
    });
    $(document).on('click', ".edit-rider", function() {
      var id;
      id = $(this).parent().parent().attr('rider-id');
      console.log("id: " + id);
      return $.ajax({
        url: "/riders/" + id + "/overlay",
        success: function(res) {
          $('.info-overlay > div').hide();
          $('.info-overlay .rider-update').html(res).show();
          return $('.info-overlay').show();
        }
      });
    });
    $(document).on('click', ".save-rider", function() {
      var rider_id;
      rider_id = $(this).attr('rider-id');
      return $.ajax({
        url: "/riders/" + rider_id,
        type: "put",
        data: {
          id: rider_id,
          rider: {
            firstname: "hello"
          }
        },
        error: function(data) {
          return console.log(data);
        }
      });
    });
    $(document).on('click', '#auto_lineup', function() {
      return $.ajax({
        url: "/events/" + event_id + "/auto_lineup",
        type: "get",
        data: {
          event_id: event_id
        },
        error: function(data) {
          return console.log(data);
        }
      });
    });
    $(document).on('click', '.time-trial-options .button-wrapper .button', function() {
      var hun_ms, min_ms, sec_ms, value;
      min_ms = $('.time #min').val() * 60 * 1000;
      sec_ms = $('.time #sec').val() * 1000;
      hun_ms = $('.time #hun').val() * 10;
      value = $(this).attr('value') || min_ms + sec_ms + hun_ms;
      if ($('.time #min').val() === -1 || $('.time #sec').val() === -1 || $('.time #hun').val() === -1) {
        value = -1;
      }
      return $.ajax({
        url: "/eventriders/set_time",
        type: "post",
        data: {
          id: $('.cur-rider').attr('eventrider-id'),
          time: value
        },
        success: function(msg) {
          return $('.cur-rider .rider-result-overlay p').html(msg);
        }
      });
    });
    return $(document).on('click', '#next-rider', function() {
      return $.ajax({
        url: "/eventriders/next",
        type: "post",
        data: {
          event_id: $('#event-preview').attr('event-id')
        },
        success: function(msg) {
          return console.log('???');
        }
      });
    });


}).call(this);

console.log('HEATRIDERS');
(function() {
  $(document).on('click', '.toggle-switch', function() {
    var data, key, value;
    $(this).parent().find('.toggle-content').toggle();
    $(this).parent().find('i').toggle();
    $switch = $(this)
    data = {};
    key = $(this).attr('update-key');
    value = $(this).find('.active').attr('value');
    data[$(this).attr('update-object')] = {};
    data[$(this).attr('update-object')][$(this).attr('update-key')] = $(this).find('.active').attr('value') === "true";
    if ($(this).hasClass('non-bool')) {
      data[$(this).attr('update-object')][$(this).attr('update-key')] = $(this).find('.active').attr('value');
    }
    $.ajax({
      url: $(this).attr('update-url'),
      type: "put",
      data: data,
      success: function(res) {
        console.log("OK");
        $switch.find('span').toggleClass('active')
        if (key === 'last_lcq_heatround') {
          $('.box-chooser.heatround-lcq li').hide();
          if (value === '-1') {
            return $('.box-chooser.heatround-lcq li').show();
          } else {
            return $('.box-chooser.heatround-lcq li[round=1]').show();
          }
        }
      }
    });
  });
  $(document).on('click', '.toggle-heat', function() {
    $('.current-heat-signal').addClass('hide')
    $(this).find('.current-heat-signal').toggleClass('hide')
    event_id = $('#event-wrapper').attr('event-id');
    heat_pointer = $(this).parent().attr('heat-pointer')
    data = { heat_pointer: heat_pointer }
    var $li, $ul;
    $li = $(this).parent().parent();
    $ul = $li.parent();
    console.log("DATA????")
    console.log(data)
    $.ajax({
      url: '/events/' + event_id + '/set_current_heat',
      type: "put",
      data: data,
      success: function(res) {
        console.log("OK");
        $li.toggleClass('selected');
        if ($ul.find('>li.selected').length > 0) {
          $ul.addClass('float');
          $ul.find('li.selected').removeClass('selected');
          return $li.addClass('selected');
        } else {
          $ul.removeClass('float');
          $ul.find('.heat-options').removeClass('available');
          $ul.css('height', 'inherit');
          $ul.find('li.selected').removeClass('selected');
        }
        console.log('JHJJJJ')
      }
    });
  });
  $(document).on('click', '.selectable', function() {
    if ($(this).hasClass('single')) {
      $(this).parent().find('.selectable').removeClass('selected');
    }
    return $(this).toggleClass('selected');
  });
  $(document).on('click', '.heatriders-in-heat li.selectable', function() {
    var $wrapper;
    $wrapper = $(this).parent().parent();
    return $wrapper.find('.heat-options').addClass('available');
  });
}).call(this);

console.log("TABLE");

(function() {
  var apply_search, apply_search_with, apply_table_filters, update_eventrider;

  apply_table_filters = function(reset) {
    var count;
    if (reset == null) {
      reset = true;
    }
    if (reset) {
      $('#eventriders-table tr').show();
    }
    if (($('#checked-in-filter[value="true"]').length)) {
      $('.td-reg[val="false"]').each(function() {
        return $(this).parent().hide();
      });
      count = $('.td-reg[val="true"]').length;
      $('#checked-in-filter .filter-value').html(count);
    }
    if (($('#checked-in-filter[value="false"]').length)) {
      $('.td-reg[val="true"]').each(function() {
        return $(this).parent().hide();
      });
      count = $('.td-reg[val="false"]').length;
      $('#checked-in-filter .filter-value').html(count);
    }
    if (($('#fee-filter[value="true"]').length)) {
      $('.td-fee[val="0"]').each(function() {
        return $(this).parent().hide();
      });
      count = $('.td-fee[val!="0"]').length;
      $('#fee-filter .filter-value').html(count);
    }
    if (($('#fee-filter[value="false"]').length)) {
      $('.td-fee[val!="0"]').each(function() {
        return $(this).parent().hide();
      });
      count = $('.td-fee[val="0"]').length;
      return $('#fee-filter .filter-value').html(count);
    }
  };

  apply_search = function() {
    var search_pattern;
    search_pattern = $('#search-table').val().toLowerCase();
    Cookies.set('search', search_pattern);
    apply_search_with(search_pattern);
    return apply_table_filters(false);
  };

  apply_search_with = function(search_pattern) {
    if (search_pattern === '') {
      return $('#eventriders-table tbody tr').show();
    } else {
      return $.each($('#eventriders-table tbody tr'), function() {
        var tr;
        tr = $(this);
        tr.show();
        return $.each($(this).find('td'), function() {
          var text;
          text = $(this).html();
          if (text.toLowerCase().indexOf(search_pattern) > -1) {
            tr.show();
            return false;
          } else {
            return tr.hide();
          }
        });
      });
    }
  };

  update_eventrider = function(data, parent, value) {
    var $td, id;
    $td = parent;
    value = $td.find('input').val();
    id = $td.parent().attr('eventrider-id');
    return $.ajax({
      url: "/eventriders/" + id + "?type=" + $('.tab-chooser li.active').attr('value'),
      type: "put",
      data: {
        eventrider: data
      },
      success: function(res) {
        var number, old, order, type;
        old = $td.attr('val');
        if (old === '') {
          old = '-';
        }
        $td.html($td.html().replace(old, value));
        $td.attr('val', value);
        $("#eventriders-table").trigger("update");
        $td.find('input').hide();
        if (number === '') {
          number = '-';
        } else {
          number = String('000' + number).slice(-3);
        }
        $td.html($td.html().replace($td.attr('val'), number));
        $td.attr('val', number);
        flash_success('Eventrider saved', 'New number is ' + number);
        reset_table_input();
        // update_toolboxes();
        $("#eventriders-table").trigger("update");
        type = $('#type-frame').attr('type');
        if (Cookies.get('sort_' + type)) {
          order = Cookies.get('sort_' + type).split('_');
          if (order[1] !== '-1') {
            return $("#eventriders-table").trigger("sorton", [[[order[1], order[0]]]]);
          }
        }
      },
      error: function(res) {
        return console.log("nay");
      }
    });
  };

  $(function() {
    var search_pattern;
    $(document).on('click', '.table-download', function() {
      return $('.download-list').toggleClass('hide');
    });
    $(document).on('click', '.trial-result', function() {
      $('th.group').hide();
      $('.td-group').hide();
      $('th.wr').hide();
      $('.td-wr').hide();
      $('th.wc').hide();
      $('.td-wc').hide();
      $('th.fastest').show();
      $('.td-fastest').show();
      $('th.place').show();
      return $('.td-place').show();
    });
    $(document).on('click', '.trial-start', function() {
      $('th.group').show();
      $('.td-group').show();
      $('th.fastest').hide();
      $('.td-fastest').hide();
      $('th.place').hide();
      $('.td-place').hide();
      $('th.wr').show();
      $('.td-wr').show();
      $('th.wc').show();
      return $('.td-wc').show();
    });
    if (Cookies.get('search')) {
      $('#search-table').attr('value', Cookies.get('search'));
      search_pattern = Cookies.get('search');
      apply_search_with(search_pattern);
    }
    $(document).on('keyup', '#search-table', function() {
      return apply_search();
    });
    $(document).on('click', '.table-filters span', function() {
      if ($(this).attr('value') === "true") {
        $(this).find('i').removeClass('active');
        $(this).attr('value', '');
        $(this).find('.filter-value').addClass('hide');
      } else if ($(this).attr('value') === "false") {
        $(this).attr('value', 'true');
        $(this).find('i:visible').addClass('active');
        $(this).find('.filter-value').removeClass('hide');
      } else {
        $(this).attr('value', 'false');
        $(this).find('i').removeClass('active');
        $(this).find('i:visible').addClass('active');
        $(this).find('.filter-value').removeClass('hide');
      }
      return apply_table_filters();
    });
    $(document).on('dblclick', '.td-team', function() {
      var curr, eventrider_id, teamevent_id, teamrider_id;
      curr = $(this);
      eventrider_id = curr.closest('tr').attr('eventrider-id');
      teamevent_id = curr.closest('tr').attr('teamevent-id');
      teamrider_id = curr.closest('tr').attr('teamrider-id');
      if (curr.find('.fa-check-circle').hasClass('hide')) {
        return $.ajax({
          url: "/teamriders/",
          type: "post",
          data: {
            teamrider: {
              eventrider_id: eventrider_id,
              teamevent_id: teamevent_id
            }
          },
          success: function(msg) {
            curr.attr("val", curr.find('.fa-check-circle').hasClass('hide'));
            curr.find('i').toggleClass('hide');
            curr.closest('tr').attr('teamrider-id', msg);
            apply_table_filters();
            if ($('#search-table').val()) {
              return apply_search();
            }
          },
          error: function(res) {
            return console.log("ERROR " + res);
          }
        });
      } else {
        return $.ajax({
          url: "/teamriders/" + teamrider_id,
          type: "delete",
          success: function(msg) {
            curr.attr("val", curr.find('.fa-check-circle-o').hasClass('hide'));
            curr.find('i').toggleClass('hide');
            apply_table_filters();
            if ($('#search-table').val()) {
              return apply_search();
            }
          },
          error: function(res) {
            return console.log("ERROR " + res);
          }
        });
      }
    });
    $(document).on('dblclick', '.td-reg', function() {
      var curr;
      curr = $(this);
      return $.ajax({
        url: "/eventriders/" + curr.closest('tr').attr('eventrider-id'),
        type: "put",
        data: {
          eventrider: {
            checked_in: curr.find('.fa-check-circle').hasClass('hide')
          }
        },
        success: function(msg) {
          curr.attr("val", curr.find('.fa-check-circle').hasClass('hide'));
          curr.find('i').toggleClass('hide');
          apply_table_filters();
          if ($('#search-table').val()) {
            return apply_search();
          }
        },
        error: function(res) {
          return console.log("ERROR " + res);
        }
      });
    });
    $(document).on('dblclick', '.td-waiver', function() {
      var curr;
      curr = $(this);
      console.log(curr.attr("val"));
      fee = false;
      if (curr.find('.fa-check').hasClass('hide')) {
        fee = true;
      }
      return $.ajax({
        url: "/eventriders/" + curr.closest('tr').attr('eventrider-id'),
        type: "put",
        data: {
          eventrider: {
            waiver: fee
          }
        },
        success: function(msg) {
          curr.attr("val", fee);
          curr.find('i').toggleClass('hide');
          apply_table_filters();
          if ($('#search-table').val()) {
            return apply_search();
          }
        },
        error: function(res) {
          return console.log("ERROR " + res);
        }
      });
    });

    $(document).on('dblclick', '.td-fee', function() {
      var curr;
      curr = $(this);
      console.log(curr.attr("val"));
      console.log("FEEE")
      fee = false;
      console.log('HELLO???')
      if (curr.find('.fa-check').hasClass('hide')) {
        fee = true;
      }
      return $.ajax({
        url: "/eventriders/" + curr.closest('tr').attr('eventrider-id'),
        type: "put",
        data: {
          eventrider: {
            ticketteer_event_fee: fee
          }
        },
        success: function(msg) {
          curr.attr("val", fee);
          curr.find('i').toggleClass('hide');
          apply_table_filters();
          if ($('#search-table').val()) {
            return apply_search();
          }
        },
        error: function(res) {
          return console.log("ERROR " + res);
        }
      });
    });
    $(document).on('dblclick', '.td-order', function() {
      return $(this).find('input').show();
    });
    $(document).on('dblclick', '.td-group', function() {
      return $(this).find('input').show();
    });
    $(document).on('keyup', 'tbody .td-order input', function(e) {
      if (e.keyCode === 13) {
        if ($(this).parent().hasClass('td-order')) {
          return update_eventrider({
            trial_number: $(this).val()
          }, $(this).parent());
        }
      }
    });
    $(document).on('keyup', 'tbody .td-group input', function(e) {
      if (e.keyCode === 13) {
        if ($(this).parent().hasClass('td-group')) {
          return update_eventrider({
            trial_group: $(this).val()
          }, $(this).parent());
        }
      }
    });
    return $(document).on('dblclick', '.td-waiver', function() {
      var curr, fee;
      curr = $(this);
      fee = false;
      if (curr.find('.fa-check').hasClass('hide')) {
        fee = true;
      }
      return $.ajax({
        url: "/eventriders/" + curr.closest('tr').attr('eventrider-id'),
        type: "put",
        data: {
          eventrider: {
            waiver: fee
          }
        },
        success: function(msg) {
          curr.attr("val", fee);
          curr.find('i').toggleClass('hide');
          apply_table_filters();
          if ($('#search-table').val()) {
            return apply_search();
          }
        },
        error: function(res) {
          return console.log("ERROR " + res);
        }
      });
    });
    return $(document).on('dblclick', '.td-fee', function() {
      var curr, fee;
      curr = $(this);
      fee = false;
      console.log('HELLO???')
      if (curr.find('.fa-check').hasClass('hide')) {
        fee = true;
      }
      return $.ajax({
        url: "/eventriders/" + curr.closest('tr').attr('eventrider-id'),
        type: "put",
        data: {
          eventrider: {
            ticketteer_event_fee: fee
          }
        },
        success: function(msg) {
          curr.attr("val", fee);
          curr.find('i').toggleClass('hide');
          apply_table_filters();
          if ($('#search-table').val()) {
            return apply_search();
          }
        },
        error: function(res) {
          return console.log("ERROR " + res);
        }
      });
    });
  });
}).call(this);


console.log('HEATS');

(function() {
  var answer_yes, change_heat_view, change_heatround, close_overlay, flash_error, flash_success, highlight_rider, placement_tablet, post_update_list_view, post_update_tablet, reset_buttons, send_delete_placement, send_placement_update, set_buttons_available, set_placement, set_selected, show_overlay;

  flash_success = function(title, message) {
    $("#flash-title").html(title);
    $("#flash-content").html(message);
    $('#flash').css('background-color', 'rgba(117, 224, 109, 0.6)');
    $('#flash').css('color', 'rgba(40, 40, 40, 0.9)');
    return $("#flash").fadeIn(500).delay(3500).fadeOut(500);
  };

  flash_error = function(title, message) {
    $("#flash-title").html(title);
    $("#flash-content").html(message);
    $('#flash').css('background-color', 'rgba(252, 93, 90, 0.6)');
    $('#flash').css('color', 'rgba(40, 40, 40, 0.9)');
    return $("#flash").fadeIn(200).delay(3500).fadeOut(200);
  };

  answer_yes = function(data) {
    var event_id;
    event_id = $('#event-wrapper').attr('event-id');
    $('.info-overlay .info').hide();
    $('.info-overlay .spinner').show();
    return $.ajax({
      url: "/events/" + event_id,
      type: "put",
      data: {
        event: data
      },
      success: function() {
        return $.ajax({
          url: "/toolboxes/" + event_id,
          success: function() {
            return $.ajax({
              url: "/heats",
              format: 'js',
              data: {
                event_id: event_id
              },
              success: function() {
                $(".info-overlay").fadeOut(700);
                return setTimeout(function() {
                  $('.info-overlay .info').show();
                  return $('.info-overlay .spinner').hide();
                }, 1000);
              }
            });
          }
        });
      }
    });
  };

  show_overlay = function(h2) {
    if (h2 == null) {
      h2 = false;
    }
    $(".info-overlay > div").hide();
    if (h2) {
      $('.info-overlay .info h2').html(h2);
    }
    $(".info-overlay .info").show();
    return $(".info-overlay").fadeIn(500);
  };

  close_overlay = function() {
    $(".info-overlay").fadeOut(500);
  };

  $(function() {
    var dns, luckyLooser;
    if (($("#live").length)) {
      setInterval((function() {
        console.log("get current heat");
        $.ajax({
          url: "/events/" + $('#live').attr('event-id') + "/get_current_heat",
          success: function(heat_id) {
            console.log("current heat :", heat_id);
            if (heat_id !== $('#live').attr('heat-id')) {
              return location.href = "/events/" + $('#live').attr('event-id') + "/current_heat?user_type=livescreen";
            }
          }
        });
      }), 3000);
    }
    $(document).ready(function() {
      return $('.live-btn').each(function(i, btn) {
        var $btn;
        $btn = $(btn);
        if ($btn.attr('type') === $('#livescreen-control').attr('mode')) {
          return $btn.addClass("active");
        }
      });
    });
    $(document).on('click', '.live-btn', function() {
      var id, type;
      type = $(this).attr('type');
      $('.live-btn').removeClass('active');
      $(this).addClass("active");
      if (type) {
        return $.ajax({
          url: "/livescreen_mode",
          type: "post",
          data: {
            mode: type
          },
          success: function(res) {
            return console.log("res:" + res);
          }
        });
      } else if ($(this).hasClass('goto')) {
        id = $('#go-to').val();
        if (id > 0) {
          return $.ajax({
            url: "/set_livescreen",
            type: "post",
            data: {
              heat_id: id
            },
            success: function(res) {
              console.log("RES?");
              return location.href = "/heats/" + id + "?user_type=livescreen_control";
            }
          });
        }
      } else {
        id = $(this).attr('heat-id');
        return $.ajax({
          url: "/set_livescreen",
          type: "post",
          data: {
            heat_id: id
          },
          success: function(res) {
            return location.href = "/heats/" + id + "?user_type=livescreen_control";
          }
        });
      }
    });
    $(document).on('click', '#survivors-to-main', function() {
      var category_id, event_id;
      category_id = $('#heat-options').attr('category-id');
      event_id = $('#event-wrapper').attr('event-id');
      return $.ajax({
        url: "/events/" + event_id + "/lcq_survivors_to_final_heats",
        data: {
          category: category_id
        },
        success: function() {
          return location.reload();
        }
      });
    });
    $(document).on('click', '#random-heat-results', function() {
      var category_id, lcq;
      category_id = $('#heat-options').attr('category-id');
      lcq = $('.box-chooser.lcq .active').hasClass('lcq');
      console.log(lcq);
      return $.ajax({
        url: "/categories/" + category_id + "/random_heat_results",
        data: {
          lcq: lcq
        },
        success: function() {
          return location.reload();
        }
      });
    });
    $(document).on('click', '.start-btn', function() {
      var id;
      id = $(this).attr('heat-id');
      return $.ajax({
        url: "/set_livescreen",
        type: "post",
        data: {
          heat_id: id
        },
        success: function(res) {
          return location.href = "/heats/" + id + "?user_type=start";
        }
      });
    });
    if (($('#live').length)) {
      $.ajax({
        url: "/set_livescreen",
        type: "post",
        data: {
          heat_id: $('#live').attr('heat-id')
        }
      });
      setInterval((function() {
        $.ajax({
          url: "/get_livescreen",
          success: function(heat_id) {
            console.log(heat_id);
            if (heat_id !== $('#live').attr('heat-id')) {
              return location.href = "/heats/" + heat_id + "?user_type=livescreen";
            }
          }
        });
        $.ajax({
          url: "/livescreen_mode",
          success: function(mode) {
            if (mode !== $('#live').attr('mode')) {
              return location.href = "/heats/" + $('#live').attr('heat-id') + "?user_type=livescreen";
            }
          }
        });
        $.ajax({
          url: "/heats/" + $('#live').attr('heat-id') + "/get_heatriders",
          success: function(res) {
            return $.each(res, function(index, heatrider) {
              var $placement;
              if (heatrider.finished > 0) {
                $placement = $('div[heatrider-id=' + heatrider.id + '] .placement-overlay');
                $placement.html(heatrider.placement);
                return $placement.fadeIn(400);
              }
            });
          }
        });
      }), 3000);
      setInterval((function() {
        console.log("get current heat");
        $.ajax({
          url: "/events/" + $('#live').attr('event-id') + "/get_current_heat",
          success: function(heat_id) {
            console.log("current heat :", heat_id);
            if (heat_id !== $('#live').attr('heat-id')) {
              return location.href = "/events/" + $('#live').attr('event-id') + "/current_heat?user_type=livescreen";
            }
          }
        });
      }), 3000);
    }
    $(document).on('click', '.box-chooser.heatround li', function() {
      return change_heatround($(this));
    });
    $(document).on('click', '.heat-btn', function() {
      return set_placement($(this));
    });
    $(document).on('click', '.delete-heat', function() {
      var id;
      show_overlay("REALLY?");
      id = $(this).attr('heat-id');
      $(".answers .no").on('click', function() {
        return close_overlay();
      });
      return $(".answers .yes").on('click', function() {
        close_overlay();
        return $.ajax({
          url: "/heats/" + id,
          type: "delete",
          success: function(res) {
            return console.log("DONE");
          }
        });
      });
    });
    $(document).on('click', '.delete-heatriders', function() {
      var id;
      show_overlay();
      id = $(this).attr('heat-id');
      $(".answers .no").on('click', function() {
        return close_overlay();
      });
      return $(".answers .yes").on('click', function() {
        close_overlay();
        return $.ajax({
          url: "/heats/" + id + "/delete_heatriders",
          type: "delete",
          success: function(res) {
            return console.log("DONE");
          }
        });
      });
    });
    $(document).on('click', '.rider-img-wrapper', function() {
      var $rider_wrapper;
      $rider_wrapper = $(this);
      highlight_rider($rider_wrapper);
      if ($('.outside-frame').hasClass('finish-view')) {
        set_buttons_available($rider_wrapper);
      } else {
        console.log("do start");
        $rider_wrapper.parent().parent().parent().find('.rider-btn').toggle();
      }
      return reset_buttons($(this));
    });
    $(document).on('click', '.next-heat', function() {
      return change_heat_view('#review-heat');
    });
    $(document).on('click', '.previous-heat', function() {
      return change_heat_view('#review-heat', -1);
    });
    $(document).on('click', '.next-heat-btn', function() {
      return location.href = "/heats/" + $('#heat').attr('next-heat-id') + "?user_type=finish";
    });
    $(document).on('click', '.unlock', function() {
      $('.heat-frame').removeClass('show');
      $('.unlock').removeClass('active');
      $(this).parent().toggleClass('show');
      return $(this).toggleClass('active');
    });
    luckyLooser = function($frame) {
      var $looser;
      $looser = $frame.find('.lucky-looser');
      if (($frame.find('.rider-img-wrapper.active .looser:visible').length)) {
        return $looser.addClass('active');
      } else {
        return $looser.removeClass('active');
      }
    };
    dns = function($frame) {
      var $looser;
      $looser = $frame.find('.dns');
      if (($frame.find('.rider-img-wrapper.active .dns-overlay:visible').length)) {
        return $looser.addClass('active');
      } else {
        return $looser.removeClass('active');
      }
    };
    $(document).on('click', '.box-chooser.lcq li', function() {
      var before;
      before = Cookies.get('lcq');
      if ($('.box-chooser.lcq li.active').hasClass('lcq')) {
        Cookies.set('lcq', true);
      } else {
        Cookies.set('lcq', false);
      }
      if (before !== Cookies.get('lcq')) {
        $('.heats').toggleClass('hide');
        return $('ul.heatround').toggleClass('hide');
      }
    });
    $(document).on('click', '.dns', function() {
      var $this, heatrider_id;
      $this = $(this);
      heatrider_id = $('.rider-img-wrapper.active').attr('heatrider-id');
      return $.ajax({
        url: "/heatriders/" + heatrider_id,
        type: "PUT",
        data: {
          heatrider: {
            finished: 0
          }
        },
        success: function(data) {
          var $parent;
          console.log('here');
          $parent = $this.parent();
          $('.lucky-looser').removeClass('active');
          $this.toggleClass('active');
          $parent.find('.rider-img-wrapper.active .looser').hide();
          return $parent.find('.rider-img-wrapper.active .dns-overlay').toggle();
        }
      });
    });
    return $(document).on('click', '.lucky-looser', function() {
      var $parent;
      $parent = $(this).parent();
      $parent.find('.dns').removeClass('active');
      $parent.find('.next-looser').toggle();
      $(this).toggleClass('active');
      $parent.find('.rider-img-wrapper.active .dns-overlay').hide();
      return $parent.find('.rider-img-wrapper.active .looser').toggle();
    });
  });

  change_heatround = function($chooser) {
    var split;
    $('.info-overlay > div').hide();
    $('.info-overlay .spinner').show();
    $('.info-overlay .spinner-text').show();
    $('.info-overlay').fadeIn(200);
    if ($('#split-heats')) {
      split = $chooser.parent().attr('first');
    } else {
      split = -1;
    }
    return $.ajax({
      url: "/heats",
      type: "get",
      format: 'js',
      data: {
        round: $chooser.attr('round'),
        split: split,
        event_id: $('#event-wrapper').attr('event-id'),
        type: 'list_view'
      },
      success: function(data) {
        $('.info-overlay').fadeOut(200);
        return flash_success("Heatround loaded", "Heats have been updated");
      },
      error: function(data) {
        $('.info-overlay').fadeOut(200);
        return flash_error("Something went wrong", "Heats could not be loaded");
      }
    });
  };

  change_heat_view = function(view, offset) {
    var heat_id;
    if (offset == null) {
      offset = 1;
    }
    heat_id = parseInt($(view).find('.heat-info').attr('heat-id')) + offset;
    view += ' .heat-frame';
    return $.ajax({
      url: "/heats/" + heat_id,
      format: 'js',
      data: {
        element_id: view
      }
    });
  };

  reset_buttons = function($rider_wrapper) {
    if (!$rider_wrapper.hasClass('active')) {
      $('.heat-btn').removeClass('active');
      return $('.heat-btn').removeClass('available');
    }
  };

  set_buttons_available = function($rider) {
    var $pane;
    $pane = $rider.parent().parent().parent().parent();
    return $pane.find('.heat-btn').addClass('available');
  };

  set_selected = function($button) {
    return $button.addClass("selected");
  };

  highlight_rider = function($rider) {
    var $frame, $overlay;
    $rider.toggleClass('active');
    $frame = $rider.parent().parent().parent();
    $overlay = $frame.find('.edit-overlay');
    $overlay.css('background-color', $rider.parent().find('.rider-color').css('background-color'));
    return $overlay.toggle();
  };

  set_placement = function($button) {
    var $pane, $rider, $selected;
    $pane = $button.parent().parent();
    $rider = $pane.find('.rider-img-wrapper.active');
    $selected = $pane.parent().find('.heatriders-in-heat li.selected');
    if ($rider.length) {
      placement_tablet($rider, $pane, $button);
    }
    if ($selected.length) {
      return send_placement_update($button, $selected.attr('heatrider-id'));
    }
  };

  placement_tablet = function($rider, $pane, $button) {
    var $clone, old_val;
    old_val = $rider.find('.placement-overlay').attr('placement');
    $pane.find('.heat-btn[val=' + old_val + ']').removeClass('selected');
    $('.heat-btn').removeClass('active');
    $clone = $pane.find('.placement-overlay[placement=' + $button.attr('val') + ']');
    if ($clone.length && $button.attr('val') > 0) {
      send_delete_placement($clone);
    }
    send_placement_update($button, $rider.attr('heatrider-id'));
    return set_selected($button);
  };

  post_update_list_view = function(data) {
    var $item, $list, $options, heatrider, j, len, ref, results;
    $list = $('ul[heat-id=' + data.heat_id + ']');
    $options = $list.parent().find('.heat-options');
    $options.removeClass('available');
    $options.find('.heat-btn').removeClass('used');
    ref = data.heatriders;
    results = [];
    for (j = 0, len = ref.length; j < len; j++) {
      heatrider = ref[j];
      if (heatrider.placement) {
        $item = $('li[heatrider-id=' + heatrider.id + ']');
        $item.attr('placement', heatrider.placement).removeClass('selected');
        $item.find('.heatrider-placement').html(heatrider.placement).show();
        results.push($options.find('.heat-btn[val=' + heatrider.finished + ']').addClass('used'));
      } else {
        results.push(void 0);
      }
    }
    return results;
  };

  post_update_tablet = function(data, $button) {
    var $overlay, $pane, placement;
    placement = data.placement;
    $overlay = $('div[heatrider-id=' + data.id + ']').find('.placement-overlay');
    console.log('over', $overlay);
    if (placement) {
      $overlay.html(placement).show();
      $overlay.attr('placement', data.finished);
    } else {
      $overlay.hide();
    }
    $button.toggleClass('active');
    $pane = $button.parent().parent();
    if ($pane.find('.placement-overlay:visible').length === 4) {
      return $pane.find('.next-heat-btn').addClass('available');
    } else {
      return $pane.find('.next-heat-btn').removeClass('available');
    }
  };

  send_delete_placement = function($clone) {
    var heatrider_id;
    heatrider_id = $clone.parent().attr('heatrider-id');
    return $.ajax({
      url: "/heatriders/" + heatrider_id + "/delete_placement",
      type: "GET",
      success: function(data) {
        if (data) {
          $clone.removeAttr('placement');
          return $clone.hide();
        } else {
          return alert("something went wrong: delete_placement");
        }
      }
    });
  };

  send_placement_update = function($button, heatrider_id) {
    $gate_nr = $button.parent().parent().parent().find('.single.selected').find('.gate-nr')
    console.log($gate_nr)
    var finished;
    finished = $button.attr('val');
    if ( $button.hasClass('gate-color') ){
      $.ajax({
        url: "/heatriders/" + heatrider_id,
        type: "PUT",
        data: {
          heatrider: {
            gate_nr_chosen: finished
          }
        },
        success: function(data) {
          color = 'gate-color-' + finished
          $gate_nr.addClass(color)
          $gate_nr.removeClass('hide')
        }
      });
    } else {
      $.ajax({
        url: "/heatriders/" + heatrider_id,
        type: "PUT",
        data: {
          heatrider: {
            finished: finished
          }
        },
        success: function(data) {
          if (data) {
            if (($('div[heatrider-id=' + heatrider_id + ']').length)) {
              return $.each(data.heatriders, function(hr) {
                var sel;
                sel = data.heatriders[hr];
                if (sel.id === parseInt(heatrider_id)) {
                  return post_update_tablet(sel, $button);
                }
              });
            } else {
              return post_update_list_view(data);
            }
          } else {
            return alert("something went wrong: no data! => put heatrider");
          }
        },
        error: function(data) {
          return alert("something went wrong: put heatrider");
        }
      });
    };
  }
}).call(this);


console.log('RIDERS');

(function() {

  $(document).on('click', '#rider-avatar', function() {
    return $('.avatar-input input').click();
  });
  // $('#min').mousewheel(function() {});
  $(document).on('click', ".picture-rider", function() {
    var event_id, id;
    id = $(this).parent().parent().attr('rider-id');
    event_id = $('#event-wrapper').attr('event-id');
    return $.ajax({
      url: "/riders/" + id + "/avatar?event_id=" + event_id,
      success: function(res) {
        $('.info-overlay > div').hide();
        $('.info-overlay .rider-picture').html(res).show();
        return $('.info-overlay').show();
      }
    });
  });
  $(document).on('change', '.avatar-input input', function() {
    $('.info-overlay .spinner').show();
    return $('#rider-img-input input').click();
  });
  $(document).on('click', '#rider-wrapper .gender-filter li', function() {
    if ($(this).attr('value') === 'female') {
      return $('input#rider_female_true')[0].click();
    } else {
      return $('input#rider_female_false')[0].click();
    }
  });
  $(document).on('click', '#rider-wrapper #rider-junior', function() {
    $('input#rider_junior')[0].click();
    return $(this).toggleClass('active');
  });


}).call(this);
